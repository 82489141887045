import React from 'react';
import './FloatingSaveButton.css';
import { Button } from 'reactstrap';
import { Icon } from '@iconify/react';
import contentSave from '@iconify/icons-mdi/content-save';

const FloatingSaveButton = props => {
    return(
        <>
        <div className="floating-button-top-div">
        <div className="floating-button-div">
        <Button color="primary" className="floating-save-button shadow-lg d-flex align-items-center" onClick={props.handleSave}>
            <div className="floating-save-button-icon-div"><Icon icon={contentSave} className="floating-save-button-icon" /></div>
            <div className="floating-button-span"><strong>Save</strong></div>
        </Button>
        </div>
        </div>
        </>
    )
}

export default FloatingSaveButton;