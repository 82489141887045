import React from "react";
import PropTypes from "prop-types";

import { Modal, Row, Col, Card, CardBody, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { confirmable, createConfirmation } from "react-confirm";

class Confirmation extends React.Component {
  render() {
    const {
      proceedLabel,
      cancelLabel,
      title,
      confirmation,
      show,
      proceed,
      enableEscape = true
    } = this.props;
    return (
      <div className="static-modal">
        <Modal 
            isOpen={show}
            toggle={() => proceed(false)}
            onClosed={() => proceed(false)}
            backdrop={enableEscape ? true : "static"}
            keyboard={enableEscape}
            >
            <ModalHeader className="modal-colored-header bg-danger" tag="h4">
                {title}
            </ModalHeader>
            <ModalBody>
                <h4>{confirmation}</h4>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => proceed(false)}>
                    {cancelLabel}
                </Button>{' '} 
                <Button color="danger" onClick={() => proceed(true)}>
                {proceedLabel}
                </Button>
            </ModalFooter> 
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function confirm(
  confirmation,
  title,
  proceedLabel = "OK",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    title,
    proceedLabel,
    cancelLabel,
    ...options
  });
}