import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { MemberContext } from '../custom-contexts/MemberContext';

const RedirectToTopCategory = () => {

    const { topCategory } = useContext(MemberContext);

    const history = useHistory();

    useEffect(() => {
        if(topCategory.hasOwnProperty('memberToken'))
        history.replace('/' + topCategory.memberToken);
    }, [topCategory])

    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
    )
}

export default RedirectToTopCategory;

