import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { CmsApi } from '../customApi/CmsApi';

export const UserContext = createContext();

// export default function useUser(){
//     return useContext(UserContext)
// }

export const UserProvider = (props) => {
    const [user, setUser] = useState(null);

    const [cmsToken, setCmsToken] = useState(null);

    const [userUpdated, setUserUpdated] = useState(false);

    const [userLoading, setUserLoading] = useState(false);

    let history = useHistory();
    let location  = useLocation();

    useMemo(() => {
        let userToken = localStorage.getItem('CMS_TOKEN');
        if (userToken){
            CmsApi.defaults.headers.common['token'] = userToken;
        }
    }, [])

    useEffect(() => {
        let userToken = localStorage.getItem('CMS_TOKEN');
        setCmsToken(userToken);
        if (cmsToken){
            axios.get(`${process.env.REACT_APP_CMS_API}/login/`, {
                headers: {
                  token: cmsToken
                }
               })
            .then(res => setUser(res.data))
            .catch(err => {
                setUser(null)}) 
            .finally(() => {
                CmsApi.defaults.headers.common['token'] = cmsToken;
            })
        } else {
            setUser(null);
        }
    }, [cmsToken])

    const loginUser = (loginInfo) => {
        setUserLoading(true);
		return axios.post('https://api.accounts.cloudsnob.com/login', loginInfo).
        then(res => {
			if(res.data.error){
                toast.error(`${res.data.error[0].msg}`, {
                    theme: 'colored',
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false;
			} else if(res.data.token) {
				localStorage.setItem('CMS_TOKEN', res.data.token);
                CmsApi.defaults.headers.common['token'] = res.data.token;
                axios.get(`${process.env.REACT_APP_CMS_API}/login`, {
                    headers: {
                      token: res.data.token
                    }
                   })
                .then(res => {
                    setUser(res.data)
                    if(location.state && location.state.from)
                        history.push(location.state.from)
                    else{            
                        toast.success('Welcome!', {
                            theme: 'colored',
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        history.push('/')
                    }
                    return true;
                })
                .catch(err => {
                    console.log(err)
                    toast.error(`${err.message}`, {
                        theme: 'colored',
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setUser(null)})
                .finally(() => setUserLoading(false));
			}
		})
        .catch(err => {
            toast.error(`${'Error'}`, {
                theme: 'colored',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
        .finally(() => setUserLoading(false));
	}

    const isLoggedIn = () => {
        return user ? true : false;
    }

    const logoutUser = () => {
        localStorage.removeItem('CMS_TOKEN');
        CmsApi.defaults.headers.common['token'] = undefined;
        toast.success("User logged out", {
            theme: 'colored',
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setUser('');
        history.push('/account/login');
        // !!!!!!!!!!!!!!!!!!! IMPORTANT: REMOVE TOKEN FROM AXIOS DEFAULTS. OMNI API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    } 


    
    return (
        <UserContext.Provider value={{ user, userLoading, userUpdated, loginUser, logoutUser, isLoggedIn }}>
            {props.children}
        </UserContext.Provider>
    );
}