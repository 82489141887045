import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Table, Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, NavLink, UncontrolledCollapse, Collapse, UncontrolledTooltip, Button, Row, Col, FormGroup, Label, Input, InputGroup, InputGroupAddon, CustomInput, Modal, ModalHeader, ModalBody, CardFooter } from 'reactstrap';
import { MemberContext } from '../custom-contexts/MemberContext';
import { Icon } from '@iconify/react';
import viewList from '@iconify/icons-mdi/view-list';
import codeJson from '@iconify/icons-mdi/code-json';
import cogIcon from '@iconify/icons-mdi/cog';
import Select from 'react-select';
import { toast } from 'react-toastify';
import FlatPicker from 'react-flatpickr';
import youtubeIcon from '@iconify/icons-mdi/youtube';
import contentCopy from '@iconify/icons-mdi/content-copy';
import deleteIcon from '@iconify/icons-mdi/delete';
import movieOpenPlayOutline from '@iconify/icons-mdi/movie-open-play-outline';
import ReactPlayer from 'react-player/youtube';
import ImageUpload from '../custom-utility-components/ImageUpload';
import axios from 'axios';
import CustomCKEditor from '../custom-utility-components/CustomCkEditor';
import FloatingSaveButton from '../custom-utility-components/FloatingSaveButton';
import { CmsApi } from '../customApi/CmsApi';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import ScrollToTop from '../custom-utility-components/ScrollToTop';
import { confirm } from '../custom-utility-components/ConfirmModal';
import TableWithPag from '../custom-utility-components/TableWithSortAndPagination';
import { Helmet } from 'react-helmet';
import cloneDeep from 'lodash.clonedeep';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const AllMembers = ({match, history, location}) => {

    // import all members from context
    const { members, setMembers } = useContext(MemberContext)

    // activeMember stores the currentMember being displayed in the view
    const [activeMember, setActiveMember] = useState('');

    // activeChildren stores all direct descendants that are one level removed from the parent. ('descendants can be parents or children)
    const [activeChildren, setActiveChildren] = useState('');

    // store the url params including all the corresponding member info in state
    const [params, setParams] = useState('');

    /*
    This app is for the most part a one parent component app that contains many child components that change based 
     upon the members that are currently showing. When the url changes the component detects which memberTokens are 
     included in the url (separated by '/') and then displays the corresponding members on the page. The url structure
     always has the active member as the last token in the url and previous params in the url usually represent the
     member tree that got the user to the current point. Actions can also be included in the url but only as the
     last param.
    The view changes depending on the member type and on the current action (edit vs display). Since the view only
     changes when the component detects the url and loads the corresponding members that can lead to potential
     side effects during the time it takes for the component to load the new data. That is why a loading state was 
     implemented to notify the component that new data is being loaded and also prevent the component from making 
     some of its usual changes until all the data is loaded and the loading state if set to false.
     */
    
    const [loading, setLoading] = useState(true)

    // whenever the app navigates to a new page set loading state to true
    useEffect(() => {
        history.listen(() => {
            if(history.action === 'POP')
                return setLoading(false)
            setLoading(true)
        })
    }, [])

    useEffect(() => {
        if(members){
            // get the memberTokens (and other info) from the url
            let urlParams = location.pathname.split('/').filter(param => param)

            let paramsArray = [];
            
            // loop through each urlParam to determine the members and actions to be taken
            urlParams.forEach((param, i) => {
                // when creating a newItem ('child') '/newItem' is appended to the end of the url
                if(param === 'newItem') {

                    // check the previous url to determine the parent member of the new item
                    let parent = members.find(member => member.memberToken === urlParams[i -1]);

                    // parent2 stores the info of the parent2 of the new item.
                    let parent2;

                    if(parent.memberType === 'parent2')
                        parent2 = parent;
                    else if (parent.memberType === 'parent3'){
                        parent2 = members.find(member => member.memberToken === parent.parent2)
                    }

                    if (parent2.lockedChild){
                        toast.error('Error: Invalid URL')
                        return history.push('/')
                    }

                    // create the newItem object 
                    let newItem = {
                        // the paramName key is only for the breadcrumbs to show the correct name. It will be deleted when the member gets saved.
                        paramName: 'New Item',
                        memberType: 'child', 
                        parent2: parent2.memberToken,
                        // get parentToken from the previous param. The parent can be either a parent2 or parent3.
                        parentTokens: [urlParams[i -1]],
                        items: {}
                    }

                    // get item (child) blueprint from the parent2
                    let childBlueprint = parent2.blueprints.child;

                    // loop through blueprint and assign correct fields to the newItem
                    childBlueprint.forEach(field => {
                        newItem.items = {
                            ...newItem.items,
                            [field.name]: ['']
                        }
                    })

                    return paramsArray.push(newItem);
                } 
                // when creating a newCategory ('parent3') '/newCategory' is appended to the end of the url
                else if (param === 'newCategory'){
                    
                    // check the previous url to determine the parent member of the new category
                    let parent = members.find(member => member.memberToken === urlParams[i -1]);

                    // parent2 stores the info of the parent2 of the new category
                    let parent2;

                    if(parent.memberType === 'parent2')
                        parent2 = parent;
                    else if (parent.memberType === 'parent3'){
                        parent2 = members.find(member => member.memberToken === parent.parent2)
                    }

                    if (parent2.lockedParent){
                        toast.error('Error: Invalid URL')
                        return history.push('/')
                    }

                    // create the newCategory object 
                    let newCategory = {
                        // the paramName key is only for the breadcrumbs to show the correct name. It will be deleted when the member gets saved.
                        paramName: 'New Category',
                        memberType: 'parent3', 
                        parent2: parent2.memberToken,
                        // get parentToken from the previous param. The parent can be either a parent2 or parent3.
                        parentTokens: [urlParams[i -1]],
                        items: {}
                    }

                    // get category (parent) blueprint from the parent2
                    let parentBlueprint = parent2.blueprints.parent;

                    // loop through blueprint and assign correct fields to the newCategory
                    parentBlueprint.forEach(field => {
                        newCategory.items = {
                            ...newCategory.items,
                            [field.name]: ['']
                        }
                    })

                    return paramsArray.push(newCategory);
                } 
                // when editing a category editCategory is appended to the url
                else if (param === 'editCategory'){
                    return
                }
                // get the full memberDetails for every urlParam that is a memberToken
                return paramsArray.push(members.find(member => member.memberToken === param))
            });

            paramsArray.forEach(param => {
                if(param === 'editCategory'){
                    // determine that the category being edited is a valid parent3
                    if (paramsArray[paramsArray.length -1].memberType !== 'parent3'){
                        toast.error('Error: Invalid URL')
                        history.push('/')
                    }
                } 
                // check that all params are valid
                else if(param === undefined){
                    console.error(param)
                    toast.error('Error: Invalid URL')
                    history.push('/')
                }

            })

            setParams(paramsArray)
            // once all params are loaded into the param state loading is finished and is set to false.
            setLoading(false)
        }
        // the  useEffect runs when there are any changes to the members or to the url
        // this may cause issues later but it fixes an issue for now
    }, [members, location.pathname])

    useEffect(() => {
        // once all members are retreived from the url and set to the param state...
        if(params){
            // set the last memberToken param in the url as the active member
            setActiveMember(cloneDeep(params[params.length -1]))
            // set all activeChildren (can be parents or children) of the activeMember
            setActiveChildren(members
                .filter(member => member.parentTokens.includes(params[params.length -1].memberToken)))
        }
    }, [params])

    const getBreadcrumbLink = (index) => {
        let link = '';
        for(let i = 0; i < index +1; i++){
            link = link.concat('/' + params[i].memberToken)
        }
        return link;
    }

    const afterItemOrCategoryDelete = (token, link) => {
        history.push({
            pathname: link,
            // the params are being passed to ensure that the table navigates to the correct tab. If default params are changed for the table component then they will need to be chnaged here as well.
            search: `?tab=${(activeMember.memberType === 'parent3') ? 'categories' : 'items'}&page=1&sizePerPage=15`,
        })
        setMembers(members.filter(member => member.memberToken !== token))
    }

    if(params)
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{activeMember.memberName || ''} | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
                <Breadcrumb>
                    {params.map((param, i) => {
                        return i !== params.length -1 ? (
                            <BreadcrumbItem key={i} className='d-flex'>
                                <Link to={getBreadcrumbLink(i)}>{param.memberName}</Link>
                                {/* {param.memberType === 'parent2' ? 
                                <div className='json-btn'>
                                <div tag="button" id={"target-" + i} className='btn btn-link no-arrow card-drop p-0'><Icon color={'#6c757d'} className='json-icon ml-1' icon={codeJson} style={{ verticalAlign: 'top' }} 
                                height="20" width='20'
                                >
                                </Icon>
                                </div>
                                <UncontrolledTooltip placement="right" target={"target-" + i}>
                                    <pre style={{ color: 'white', overflow: 'hidden', width: '500px' }}>{JSON.stringify(param.blueprints, null, 2)}</pre>
                                </UncontrolledTooltip>
                                </div>
                                : ''} */}
                            </BreadcrumbItem>
                        ) : (
                            <BreadcrumbItem active key={i} className='d-flex'>
                                {activeMember.hasOwnProperty('memberToken') ? activeMember.memberName : activeMember.paramName}
                                {/* Only add json icon in admin */}
                                {/* {param.memberType === 'parent2' ? 
                                <div className='json-btn'>
                                <div tag="button" id="target2" className='btn btn-link no-arrow card-drop p-0'><Icon color={'#6c757d'} className='json-icon ml-1' icon={codeJson} style={{ verticalAlign: 'top' }} 
                                height="20" width='20'
                                >
                                </Icon>
                                </div>
                                <UncontrolledTooltip placement="right" target="target2">
                                    <pre style={{ color: 'white', overflow: 'hidden' }}>{JSON.stringify(param.blueprints, null, 2)}</pre>
                                </UncontrolledTooltip>
                                </div>
                                : ''} */}
                            </BreadcrumbItem>
                        );
                    })}
                </Breadcrumb>
                {activeMember.memberType === 'parent1' &&
                <Parent1View members={activeChildren} setLoading={setLoading}/>
                }
                {/* {activeMember.memberType === 'parent2' &&
                    <Parent2View activeMember={activeMember} memberChildren={activeChildren} members={members} />
                } */}
                {(activeMember.memberType === 'parent2' || (activeMember.memberType === 'parent3' && !activeMember.hasOwnProperty('paramName') && location.pathname.slice(-12) !== 'editCategory')) && 
                    <TableWithPag activeMember={activeMember} memberChildren={activeChildren} members={members} loading={loading} setLoading={setLoading} />
                }
                {(activeMember.memberType === 'child' || (activeMember.memberType === 'parent3' && (activeMember.hasOwnProperty('paramName') || location.pathname.slice(-12) === 'editCategory'))) &&
                    <Parent3AndChildView activeMember={activeMember} setActiveMember={setActiveMember} members={members} setMembers={setMembers} navigateOnDelete={afterItemOrCategoryDelete} navigateOnDeleteURL={getBreadcrumbLink(params.length -2)} setLoading={setLoading} />
                }
        </>
        )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
        )
}

const Parent1View = ({members, loading, setLoading}) => {

    let history = useHistory();
    let location = useLocation();

    return(
        <Card>
                <CardBody>
                    <Table className="mb-0" hover>
                        <thead className='bg-light'>
                            <tr>
                                <th>Name</th>
                                {/* <th>Blueprint</th> */}
                                {/* Only add icon in admin mode */}
                                {/* <th>Token</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member, index) => {
                                return (
                                    <tr key={member.memberToken} 
                                    onClick={() => history.push(location.pathname + '/' + member.memberToken)}
                                    >
                                        <th scope="row">
                                            {member.memberName}
                                            {/* Only add icon in admin mode */}
                                            {/* {member.memberType === 'parent2' ? 
                                            <abbr title="Blueprint">
                                            <Icon style={{ verticalAlign: 'top' }}
                                            // className='btn btn-link'
                                            icon={viewList} 
                                            color="#39afd1"
                                            /></abbr> : ''} */}
                                        </th>
                                        {/* <td>{member.memberType === 'parent2' ? <Icon icon={viewList} /> : ''}</td> */}
                                        {/* Only add icon in admin mode */}
                                        {/* <td>{member.memberToken}</td> */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
    )
}

const Parent2View = ({activeMember, memberChildren, members}) => {

    let history = useHistory();
    let location = useLocation();

    // scroll to top before navigating to another page. This solves the issue when react router navigates to another page but preserves the scroll position from the previous page resulting in the new page not being positioned at the top of the page.
    const scrollToTopandNavigate = (e = '', path) => {
        if(e)
            e.preventDefault()
        window.scrollTo(0, 0);
        history.push(path);
    }

    return(
        <>
        {(activeMember.parent3List.length > 0 || !activeMember.lockedParent) &&
            <Row className='d-flex justify-content-center'>
                <Col sm={10} md={8} lg={6}>
                    <Card>
                        <div className="table-responsive">
                            <Table className="table mb-0" hover>
                                <thead className="bg-light">
                                    <tr className='disable-table-hover'>
                                        <th className="text-center">Categories</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activeMember.parent3List.map(member => members.find(item => item.memberToken === member))
                                    .filter(member => member)
                                    .map(member => (
                                        <tr onClick={() => history.push(location.pathname + '/' + member.memberToken)}>
                                            {/* <td>Subtotal :</td> */}
                                            <td className="text-center">{member.memberName}</td>
                                        </tr>
                                    ))
                                    }
                                    {!activeMember.lockedParent &&
                                        <tr className='disable-table-hover'>
                                            {/* <td>Subtotal :</td> */}
                                            <td className="text-center"><a href="#" onClick={(e) => e.preventDefault()}><strong>+ Add Category</strong></a></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Col>
            </Row>}
            {(memberChildren.filter(member => member.memberType === 'child').length > 0 || !activeMember.lockedChild) && 
            <Row className='d-flex justify-content-center'>
            <Col sm={10} md={8} lg={6}>
            <Card>
                <div className="table-responsive">
                    <Table className="table mb-0" hover>
                        <thead className="bg-light">
                            <tr className='disable-table-hover'>
                                <th className="text-center">Items</th>
                            </tr>
                        </thead>
                        <tbody>
                            {memberChildren.filter(member => member.memberType === 'child')
                            .map(member => (
                                <tr onClick={() => scrollToTopandNavigate('', location.pathname + '/' + member.memberToken)}
                                >
                                    {/* <td>Subtotal :</td> */}
                                    <td className="text-center">{member.memberName}</td>
                                </tr>
                            ))
                            }
                            {!activeMember.lockedChild &&
                                <tr className='disable-table-hover'>
                                    {/* <td>Subtotal :</td> */}
                                    <td className="text-center"><a href="#" onClick={e => scrollToTopandNavigate(e, location.pathname + '/newItem')}><strong>+ Add Item</strong></a></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </Card>
            </Col>
            </Row>
            }
            </>
    )
}

const Parent3AndChildView = ({ activeMember, setActiveMember, members, setMembers, navigateOnDelete, navigateOnDeleteURL, setLoading }) => {

    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        setLoading(false);
    }, [])

    console.log("ACTIVE MEMBER", activeMember)

    const [parent2, setParent2] = useState(members.find(member => member.memberToken === activeMember.parent2));

    const returnCorrectFieldFromParent = (memberType, fieldName) => {
        // check if member if parent3 or child so the correct blueprint can be assigned
        let type = memberType === 'parent3' ? 'parent' : 'child';

        let field = parent2.blueprints[type].find(field => field.name === fieldName)
        if(field !== undefined)
            field.index = parent2.blueprints[type].findIndex(field => field.name === fieldName)
        
        return field
    };

    const [listOfFields, setListOfFields] = useState((activeMember.hasOwnProperty('items')) ? Object.keys(activeMember.items).map(item => returnCorrectFieldFromParent(activeMember.memberType, item)) : [])

    console.log(listOfFields);

    const handleImageUpload = (e, fieldName, i) => {
        const file = e.target.files[0];

        let payload = new FormData();
    
        payload.append('ev_file', file);

        axios.post('https://fs1.cloudsnob.com/upload', payload, {
            headers: {
                'upload_token': process.env.REACT_APP_FS1_TOKEN
              }
        })
        .then(res => {
            const {domain, path} = res.data;
            let newMember = {...activeMember};
            newMember.items[fieldName][i] = domain + path;
            setActiveMember(newMember)
        })
    }

    const deleteImage = async (fieldName, i) => {
        if (await confirm("Deleted images cannot be recovered. Do you want to proceed?", "Deleting Image...", "Delete") === false)
            return

        let newMember = {...activeMember};
        newMember.items[fieldName][i] = '';
        setActiveMember(newMember)
    }

    // useEffect(() => {

    // }, [])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const [videoModal, setVideoModal] = useState(false);

    const [tempVideo, setTempVideo] = useState('')

    const toggleVideoModal = (video) => {
        if(!videoModal)
            setTempVideo(video)
        else
            setTempVideo('')
        setVideoModal(!videoModal)
    }

    // this is to validate a youtube link

    async function resolveYoutubeVideo(value){

        let youtubeId = '';

        let firstCheck;

        let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        let match = value.match(regExp);

        if (match && match[2].length == 11) {
            youtubeId = match[2];
            try{
                firstCheck = await axios.get('https://www.youtube.com/oembed?format=json&url=https://www.youtube.com/watch?v=' + youtubeId)

                return {
                    youtubeId: youtubeId,
                    youtubeThumbnail: firstCheck.data.thumbnail_url
                }
            } catch (err) {
                console.log(err)
            }
        }

        // if(firstCheck !== undefined)
        //     return {
        //         youtubeId: youtubeId,
        //         youtubeThumbnail: firstCheck.data.thumbnail_url
        //     }

        let secondCheck;

        try{
            secondCheck = await axios.get('https://www.youtube.com/oembed?format=json&url=https://www.youtube.com/watch?v=' + value)
        } catch (err) {
            secondCheck = false
        }

        if(secondCheck !== false)
            return {
                youtubeId: value,
                youtubeThumbnail: secondCheck.data.thumbnail_url
            }
        else 
            throw new Error('Not a valid Youtube link');

        // if (youtubeId) return youtubeId;

        // let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        // let match = value.match(regExp);

        // if (match && match[2].length == 11) {
        //     console.log(match)
        //     youtubeId = match[2];
        //     await axios.get('https://www.youtube.com/oembed?format=json&url=https://www.youtube.com/watch?v=' + youtubeId)
        //     .then(res => true)
        //     .catch(err => {
        //         youtubeId = ''
        //     })
        // } else {
        //     youtubeId = '';
        // }
        // return youtubeId
    }

    // function resolveYoutubeVideo(value) {
    //     if(value.startsWith("https://youtu.be/")){ // short link
    //         value = value.substring(17);
    //     } else if(value.startsWith("https://www.youtube.com/watch?v=")) { // regular url
    //         let listKeyIndex = value.indexOf("&list");
    //         if(listKeyIndex < 0) value = value.substring(32);
    //         else value = value.substring(32, listKeyIndex);
    //     } else if(value.startsWith("https://www.youtube.com/embed/")) { // embed link
    //         value = value.substring(30);
    //     } else if(value.search("https://www.youtube.com/embed/")) {  // iframe with embed link
    //         let fromIndex = value.search("https://www.youtube.com/embed/");
    //         fromIndex += 30;
    //         let endIndex = value.indexOf('"', fromIndex);
    //         value = value.substring(fromIndex, endIndex);
    //     }
    //     return value;
    // }

    const addField = (fieldName, fieldType) => {
        let newMember = {...activeMember};
        newMember.items[fieldName].push('');
        setActiveMember(newMember)
    }

    const onSave = () => {

        if (activeMember.hasOwnProperty('memberToken')){
            let tempCopy = { ...activeMember }
            delete tempCopy.memberToken
            delete tempCopy.createdOn
            delete tempCopy.lastEdited
            delete tempCopy.archived
            delete tempCopy.parent2
            // this is a temporary measure since a key that was supposed to deleted before saving was accidentally saved
            if(tempCopy.hasOwnProperty('_navigate'))
                delete tempCopy._navigate

            let saveMember = CmsApi.post('/members/' + activeMember.memberToken, tempCopy)
            .then(res => {

                let memberIndex = members.findIndex(member => member.memberToken === res.data.memberToken);

                let newMemberList = [ ...members ]

                newMemberList[memberIndex] = res.data;

                setMembers(newMemberList);        
            })
            .catch(err => alert(err))

            toast.promise(
                saveMember,
                {
                    pending: {
                        render() {
                            return "Saving..."
                        },
                        icon: true,
                    },
                    success: {
                        render(res) {
                            return `Successfully Saved`
                        },
                        // other options
                        icon: true,
                    },
                    error: {
                        render(err) {
                            // When the promise reject, data will contains the error
                            return `err`
                        },
                        icon: true,
                    }
                }
            )

        } else {
            let tempCopy = { ...activeMember }
            delete tempCopy.parent2;
            delete tempCopy.paramName;

            let saveMember = CmsApi.post('/members/' + tempCopy.memberType, tempCopy)
            .then(({data}) => {
                if (data.hasOwnProperty('error'))
                    throw new Error('Error')
                setMembers([
                    ...members,
                    data
                ])
                if (data.memberType === 'child')
                    history.replace(location.pathname.slice(0, -7) + data.memberToken);
                if (data.memberType === 'parent3')
                    history.replace(location.pathname.slice(0, -12));
            })
            .catch(err => toast.error(err.message))

            toast.promise(
                saveMember,
                {
                    pending: {
                        render() {
                            return "Saving..."
                        },
                        icon: true,
                    },
                    success: {
                        render(res) {
                            return `Successfully Saved`
                        },
                        // other options
                        icon: true,
                    },
                    error: {
                        render(err) {
                            // When the promise reject, data will contains the error
                            return `err`
                        },
                        icon: true,
                    }
                }
            )
        }
    }

    const onDelete = async () => {

        if (!activeMember.memberToken)
            return

        if (await confirm("Deleted members cannot be recovered. Do you want to proceed?", "Deleting Member...", "Delete") === false)
            return
        
        let deleteMember = CmsApi.delete('/members/' + activeMember.memberToken)
        .then(res => {
            navigateOnDelete(activeMember.memberToken, navigateOnDeleteURL)
        })
        .catch(err => console.error(err))

        toast.promise(
            deleteMember,
            {
                pending: {
                    render() {
                        return "Deleting..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Successfully Deleted`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const deleteField = async (fieldName, index) => {
        if (await confirm(`Are you sure you want to delete '${fieldName}(${index + 1})'? This cannot be undone.`, "Deleting Field...", "Delete") === false)
            return

        let memberCopy = { ...activeMember }
        memberCopy.items[fieldName].splice(index, 1)
        setActiveMember(memberCopy)
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const convertToSlug = (string) => string?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");

    const generateSlug = (fieldName, i) => {
        let slug = activeMember.memberName;
        if(activeMember.items[fieldName].includes(slug))
            slug += '-' + Math.random().toString(36).slice(-2);
        if(activeMember.items[fieldName].includes(slug))
            generateSlug(fieldName)
        else{
            let newMember = {...activeMember};
            newMember.items[fieldName][i] = convertToSlug(slug);
            return setActiveMember(newMember)
        }
    }

    return(
        <>
            <Row className='d-flex justify-content-center'>
                <Col sm={10} md={8} lg={6}>
            <Card>
                <CardBody>
                    <Row className='d-flex justify-content-center'>
                    <Col>
                        <Card>
                            <CardHeader className='bg-light d-flex justify-content-center'>
                                <FormGroup className='d-flex align-items-center my-0'>
                                        <Label className='my-0 h4 mr-1'>Name: </Label>
                                        <Input type="text" 
                                        className='h4'
                                        value={activeMember.memberName} 
                                        onChange={(e) => setActiveMember({
                                            ...activeMember,
                                            memberName: e.target.value
                                        })}
                                        ></Input>
                                </FormGroup>
                                {/* <h4>{activeMember.memberName}</h4> */}
                            </CardHeader>
                            <CardBody>
                {listOfFields
                .filter(field => field !== undefined)
                .sort((a, b) => a.index < b.index ? -1 : 1)
                .map((field, index) => {

                if (field.type === 'text')
                    return (
                        <>
                            {activeMember.items[field.name].map((item, i) => (
                                <FormGroup key={i}>
                                    <div className='d-flex justify-content-between mb-1'>
                                    <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                    {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                    </div>
                                    <Input type="text" 
                                    value={item} 
                                    onChange={(e) => {
                                        let newMember = {...activeMember};
                                        newMember.items[field.name][i] = e.target.value;
                                        return setActiveMember(newMember)
                                    }}
                                    ></Input>
                                </FormGroup>
                            ))}
                            {(field.infinate) && 
                            <div className='d-flex justify-content-center mb-2'>
                                <Button color={'primary'} className="btn-rounded"
                                size="sm" 
                                onClick={() => addField(field.name, field.type)}
                                >
                                    + Add {capitalizeFirstLetter(field.type)} Field
                                </Button>
                            </div>
                            }
                            {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                        </>
                    )

                if (field.type === 'textarea')
                return (
                    <>
                        {activeMember.items[field.name].map((item, i) => (
                            <FormGroup key={i}>
                                <div className='d-flex justify-content-between mb-1'>
                                    <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                    {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                </div>
                                <Input type="textarea" 
                                rows="6"
                                value={item} 
                                onChange={(e) => {
                                    let newMember = {...activeMember};
                                    newMember.items[field.name][i] = e.target.value;
                                    return setActiveMember(newMember)
                                }}
                                ></Input>
                            </FormGroup>
                    ))}
                        {(field.infinate) && 
                        <div className='d-flex justify-content-center mb-2'>
                            <Button color={'primary'} className="btn-rounded"
                            size="sm" 
                            onClick={() => addField(field.name, field.type)}
                            >
                                + Add {capitalizeFirstLetter(field.type)} Field
                            </Button>
                        </div>
                        }
                        {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                    </>
                )

                if (field.type === 'UniqueUrlSlug')
                return (
                    <>
                        {activeMember.items[field.name].map((item, i) => (
                            <FormGroup>
                                <div className='d-flex justify-content-between mb-1'>
                                    <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                    {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                </div>
                                <InputGroup>
                                    <Input type="text"
                                        value={item} 
                                        onChange={(e) => {
                                            let newMember = {...activeMember};
                                            newMember.items[field.name][i] = convertToSlug(e.target.value);
                                            return setActiveMember(newMember)
                                        }}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button
                                            className="btn btn-secondary input-group-text py-0 px-1"
                                            onClick={() => generateSlug(field.name, i)}
                                        >
                                            <Icon icon={cogIcon} width="25" height="25" />
                                        </Button>
                                    </InputGroupAddon>
                                    <InputGroupAddon addonType="append">
                                        <Button
                                            className="btn btn-secondary input-group-text py-0 px-1"
                                            id="copy-slug"
                                            onClick={() => copyToClipboard(item)}
                                            disabled={!item}
                                        >
                                            <Icon icon={contentCopy} width="25" height="25" />
                                        </Button>
                                        {(item) && <UncontrolledTooltip placement="top" target="copy-slug" trigger="legacy">
                                            '{item}' copied to clipboard
                                        </UncontrolledTooltip>}
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        ))}
                        {(field.infinate) && 
                        <div className='d-flex justify-content-center mb-2'>
                            <Button color={'primary'} className="btn-rounded"
                            size="sm" 
                            onClick={() => addField(field.name, field.type)}
                            >
                                + Add {capitalizeFirstLetter(field.type)} Field
                            </Button>
                        </div>
                        }
                        {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                    </>
                )
                
                if (field.type === 'number')
                return (
                    <>
                        {activeMember.items[field.name].map((item, i) => (
                            <FormGroup key={i}>
                                <div className='d-flex justify-content-between mb-1'>
                                    <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                    {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                </div>
                                <Input type="number" 
                                value={item} 
                                onChange={(e) => {
                                    let newMember = {...activeMember};
                                    newMember.items[field.name][i] = e.target.value;
                                    return setActiveMember(newMember)
                                }}
                                ></Input>
                            </FormGroup>
                        ))}
                        {(field.infinate) && 
                        <div className='d-flex justify-content-center mb-2'>
                            <Button color={'primary'} className="btn-rounded"
                            size="sm" 
                            onClick={() => addField(field.name, field.type)}
                            >
                                + Add {capitalizeFirstLetter(field.type)} Field
                            </Button>
                        </div>
                        }
                        {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                    </>
                )

                if (field.type === 'wysiwyg')
                return (
                    <>
                        {activeMember.items[field.name].map((item, i) => (
                            <FormGroup key={i}>
                                <div className='d-flex justify-content-between mb-1'>
                                    <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                    {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                </div>
                                <CustomCKEditor 
                                toolbarOptions={field.hasOwnProperty('wysiwygOptions') ? field.wysiwygOptions : []}
                                data={item}
                                onChange={(data) => {
                                    let newMember = {...activeMember};
                                    newMember.items[field.name][i] = data;
                                    return setActiveMember(newMember)
                                }}
                                />
                            </FormGroup>
                        ))}
                        {(field.infinate) && 
                        <div className='d-flex justify-content-center mb-2'>
                            <Button color={'primary'} className="btn-rounded"
                            size="sm" 
                            onClick={() => addField(field.name, field.type)}
                            >
                                + Add {capitalizeFirstLetter(field.type)} Field
                            </Button>
                        </div>
                        }
                        {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                    </>
                )
                
                // need to change the date to be saved as an iso string onChange
                if (field.type === 'date')
                return (
                    <>
                        {activeMember.items[field.name].map((item, i) => (
                            <FormGroup key={i}>
                                <div className='d-flex justify-content-between mb-1'>
                                    <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                    {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                </div>
                                <InputGroup>
                                    <FlatPicker
                                    className='form-control rounded-left bg-white'
                                    value={item} 
                                    onChange={(date) => {
                                        let newMember = {...activeMember};
                                        newMember.items[field.name][i] = Date.parse(date[0]);
                                        return setActiveMember(newMember)
                                    }}
                                    options={
                                        {
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d",
                                        }
                                    }
                                    placeholder="Choose date..."
                                    ></FlatPicker>
                                    <InputGroupAddon 
                                    addonType="append"
                                    >
                                        <span className="input-group-text py-0 px-1">
                                            <Icon icon={calendarRange} width="25px"/>
                                        </span>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        ))}
                        {(field.infinate) && 
                            <div className='d-flex justify-content-center mb-2'>
                            <Button color={'primary'} className="btn-rounded"
                            size="sm" 
                            onClick={() => addField(field.name, field.type)}
                            >
                                + Add {capitalizeFirstLetter(field.type)} Field
                            </Button>
                            </div>
                        }
                        {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                    </>
                )

                if (field.type === 'youtubeLink')
                return (
                    <>
                        {activeMember.items[field.name].map((item, i) => (
                            <>
                            <FormGroup key={i}>
                                <div className='d-flex justify-content-between mb-1'>
                                    <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                    {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                </div>
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text py-0 px-1">
                                        <Icon icon={youtubeIcon} width="25px"/>
                                    </span>
                                </InputGroupAddon>
                                <Input type="text" name="videoLink" id="videoLink"
                                    value={item}
                                    onChange={(e) => {
                                        // e.persist();
                                        // e.target.parentElement.parentNode.appendChild(<div>Hello</div>)
                                        // console.log(e.target.parentElement.parentNode)
                                        let currentTarget = e.currentTarget;
                                        let value = e.target.value;
                                        if(!value){
                                            e.currentTarget.className = "form-control"
                                            let newMember = {...activeMember};
                                            newMember.items[field.name][i] = '';
                                            return setActiveMember(newMember)
                                        } else if (value.length < 11){
                                            e.currentTarget.className = "form-control is-invalid"
                                            let newMember = {...activeMember};
                                            newMember.items[field.name][i] = value;
                                            return setActiveMember(newMember)
                                        }
                                            
                                        
                                        resolveYoutubeVideo(value)
                                        .then(res => {
                                            currentTarget.className = "form-control"
                                            let newMember = {...activeMember};
                                            newMember.items[field.name][i] = res.youtubeId;
                                            return setActiveMember(newMember)
                                        })
                                        .catch(err => {
                                            currentTarget.className = "form-control is-invalid"
                                            let newMember = {...activeMember};
                                            newMember.items[field.name][i] = value;
                                            return setActiveMember(newMember)
                                        })
                                        // if(!!resolveYoutubeVideo(value) == true){
                                        //     e.currentTarget.className = "form-control"
                                        //     let newMember = {...activeMember};
                                        //     newMember.items[field.name][i] = resolveYoutubeVideo(value);
                                        //     return setActiveMember(newMember)
                                        // } else {
                                        //     e.currentTarget.className = "form-control is-invalid"
                                        //     let newMember = {...activeMember};
                                        //     newMember.items[field.name][i] = value;
                                        //     return setActiveMember(newMember)
                                        // }
                                        
                                    }}
                                    placeholder="https://www.youtube.com/..."
                                />
                                {/* <InputGroupAddon addonType="append">
                                    <Button
                                        className="btn btn-secondary input-group-text py-0 px-1"
                                        onClick={() => toggleVideoModal(item)}
                                        disabled={!item}
                                    >
                                        <Icon icon={movieOpenPlayOutline} width="25" height="25" />
                                    </Button>
                                </InputGroupAddon> */}
                                </InputGroup>
                                <div className='bg-light mt-2 rounded d-flex justify-content-center overflow-hidden'>
                                <ReactPlayer
                                    width="100%"
                                    url={`https://www.youtube.com/watch?v=${item}`}
                                    controls={true} 
                                    onError={(e) => console.log(e)}
                                    config={{
                                        youtube: {
                                          onUnstarted: console.log
                                        }
                                      }}
                                    
                                />
                                </div>
                            </FormGroup>
                            </>
                        ))}
                        <VideoModal modal={videoModal} toggle={toggleVideoModal} video={tempVideo} />
                        {(field.infinate) && 
                        <div className='d-flex justify-content-center mb-2'>
                            <Button color={'primary'} className="btn-rounded"
                            size="sm" 
                            onClick={() => addField(field.name, field.type)}
                            >
                                + Add {capitalizeFirstLetter(field.type)} Field
                            </Button>
                        </div>
                        }
                        {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                    </>
                )
                if (field.type === 'image')
                    return (
                        <>
                            <Row className='mb-2'>
                            {activeMember.items[field.name].map((item, i) => (
                                <Col md={6} key={i}>
                                {/* <FormGroup className='d-flex flex-column align-items-center'> */}
                                <div className='d-flex justify-content-between mb-1'>
                                        <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                        {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                    </div>
                                    <ImageUpload 
                                    handleImageUpload={handleImageUpload}
                                    fieldName={field.name}
                                    i={i} 
                                    image={item} 
                                    deleteImage={deleteImage}
                                    allowDelete={true}
                                    imageSize={'200'}
                                    />
                                {/* </FormGroup> */}
                                </Col>
                            ))}
                            </Row>
                            {(field.infinate) && 
                            <div className='d-flex justify-content-center mb-2'>
                                <Button color={'primary'} className="btn-rounded"
                                size="sm" 
                                onClick={() => addField(field.name, field.type)}
                                >
                                    + Add {capitalizeFirstLetter(field.type)} Field
                                </Button>
                            </div>
                            }
                            {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                        </>
                    )

                if (field.type === 'switch')
                    return (
                        <>
                            {activeMember.items[field.name].map((item, i) => (
                                <FormGroup key={i}>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                        {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                    </div>
                                    <CustomInput
                                        type="switch"
                                        id={`switch-${field.name || ''}-${(field.infinate) && `${i + 1}`}`}
                                        checked={item}
                                        onChange={(e) => {
                                            let newMember = {...activeMember};
                                            newMember.items[field.name][i] = e.target.checked;
                                            return setActiveMember(newMember)
                                        }}
                                        // label="Turn on this custom switch"
                                    />
                                </FormGroup>
                            ))}
                            {(field.infinate) && 
                                <div className='d-flex justify-content-center mb-2'>
                                <Button color={'primary'} className="btn-rounded"
                                size="sm" 
                                onClick={() => addField(field.name, field.type)}
                                >
                                    + Add {capitalizeFirstLetter(field.type)} Field
                                </Button>
                                </div>
                            }
                            {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                        </>
                    )

                if (field.type === 'select')
                    return (
                        <>
                            {activeMember.items[field.name].map((item, i) => (
                                <FormGroup key={i}>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <Label className='d-flex align-items-center m-0'>{field.name}{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                        {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                    </div>
                                    <Select
                                        id="itemType"
                                        menuPlacement="auto"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={(select) => {
                                            let newMember = {...activeMember};
                                            newMember.items[field.name][i] = select.value;
                                            return setActiveMember(newMember)
                                        }}
                                        placeholder="Select option..."
                                        value={item ? {value: item, label: item} : ''}
                                        isSearchable={false}
                                        options={field.selectOptions.map(option => { return {value: option, label: option} })}>
                                    </Select>
                                </FormGroup>
                            ))}
                            {(field.infinate) && 
                                <div className='d-flex justify-content-center mb-2'>
                                <Button color={'primary'} className="btn-rounded"
                                size="sm" 
                                onClick={() => addField(field.name, field.type)}
                                >
                                    + Add {capitalizeFirstLetter(field.type)} Field
                                </Button>
                                </div>
                            }
                            {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                        </>
                    )

                    if (field.type === 'multySelect')
                    return (
                        <>
                            {activeMember.items[field.name].map((item, i) => (
                                <FormGroup key={i}>
                                    <div className='d-flex justify-content-between mb-1'>
                                        <Label className='d-flex align-items-center m-0'>{field.name}
                                        <small>&nbsp;{'(select one or more options)'}</small>{(field.infinate) && <small> ({i + 1})</small>}</Label>
                                        {(field.infinate && activeMember.items[field.name].length > 1) && <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                                    onClick={() => deleteField(field.name, i)}
                                    >
                                        <Icon icon={deleteIcon} width="16" height="16"/>
                                    </Button>}
                                    </div>
                                    <Select
                                        isMulti
                                        id="itemType"
                                        menuPlacement="auto"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={(selections) => {
                                            let newMember = {...activeMember};
                                            if (selections === null){
                                                newMember.items[field.name][i] = []
                                                return setActiveMember(newMember)
                                            }
                                            newMember.items[field.name][i] = []
                                            selections.forEach((select, index) => {
                                                newMember.items[field.name][i][index] = select.value;
                                            })
                                            return setActiveMember(newMember)
                                        }}
                                        placeholder="Select one or more options..."
                                        value={item !== null && (typeof item === 'object' && item.length) ? item.map(x => {
                                            return {value: x, label: x}
                                        }) : ''}
                                        isSearchable={false}
                                        options={field.selectOptions.map(option => { return {value: option, label: option} })}>
                                    </Select>
                                </FormGroup>
                            ))}
                            {(field.infinate) && 
                                <div className='d-flex justify-content-center mb-2'>
                                <Button color={'primary'} className="btn-rounded"
                                size="sm" 
                                onClick={() => addField(field.name, field.type)}
                                >
                                    + Add {capitalizeFirstLetter(field.type)} Field
                                </Button>
                                </div>
                            }
                            {(index + 1 !== listOfFields.length) && <hr className='w-75' 
                            style={{ borderTop: '2px solid #eef2f7' }}
                            />}
                        </>
                    )
            })}
            </CardBody>
            <CardFooter>
                <Row>
                    <Col className='d-flex justify-content-between'>
                        <Button color='danger' size="lg" onClick={onDelete} disabled={!activeMember.memberToken}>
                            Delete
                        </Button>
                        <Button color='primary' size="lg" onClick={onSave}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </CardFooter>
            </Card>
            </Col>
            </Row>
                </CardBody>
            </Card>
            </Col>
            </Row>
            <FloatingSaveButton handleSave={onSave} />
        </>
    )
}

const VideoModal = props => {

    const {modal, toggle, video} = props;

    const copyToClipboard = (e, text) => {
        e.preventDefault()
        if (video.includes('youtube'))
            navigator.clipboard.writeText(text);
        else
            navigator.clipboard.writeText(`https://www.youtube.com/watch?v=${text}`);
    }

    return(
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Play Video
            </ModalHeader>
            <ModalBody>
                <ReactPlayer
                    width="100%"
                    url={`https://www.youtube.com/watch?v=${video}`}
                    controls={true} 
                />
                <div className="mt-2">
                <p><strong>Video URL:</strong> {video.includes('youtube') ? video : `https://www.youtube.com/watch?v=${video}`}
                <a href="#" id="copy-link" onClick={(e) => copyToClipboard(e, video)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>
                </p>
                </div>
                <UncontrolledTooltip placement="top" target="copy-link" trigger="legacy">
                {video.includes('youtube') ? `'${video}'` : `'https://www.youtube.com/watch?v=${video}'`} copied to clipboard
                </UncontrolledTooltip>
            </ModalBody>
        </Modal>
    )
}

export default AllMembers;