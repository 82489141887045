import { defaults } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap';
import { Icon } from '@iconify/react';
import dragIcon from '@iconify/icons-mdi/drag';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import arrowUpDownBold from '@iconify/icons-mdi/arrow-up-down-bold';
import { CmsApi } from '../customApi/CmsApi';
import { useHistory } from 'react-router-dom';
import { MemberContext } from '../custom-contexts/MemberContext';
import { toast } from 'react-toastify';

const ChildDragandDrop = props => {

    const { data, setData } = props;

    const [isDnDModalOpen, SetIsDnDModalOpen] = useState(false);    

    const toggleDnDModal = () => {
        SetIsDnDModalOpen(!isDnDModalOpen)
    }

    return(
        <>
            <Button onClick={toggleDnDModal} className="d-flex align-items-center "><Icon icon={arrowUpDownBold} width="20" height="20" className='mr-1'/>Reorder</Button>
            <ReorderModal isOpen={isDnDModalOpen} toggle={toggleDnDModal} data={data} setData={setData}/>
        </>
    )

}

const ReorderModal = props => {

    const { isOpen, toggle, data, setData } = props;

    const { refreshMembers } = useContext(MemberContext);

    const [originalCopyOfData, setOriginalCopyOfData] = useState('');

    const [localDataState, setLocalDataState] = useState(data);

    useEffect(() => {
        if(isOpen){
            setLocalDataState(data)
            setOriginalCopyOfData(data)
        } else {
            setOriginalCopyOfData('')
        }
    }, [isOpen])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(localDataState, source.index, destination.index);
            setLocalDataState(items)

        } else {
            return
        }
    }

    const preToggle = () => {
        setLocalDataState(originalCopyOfData)
        toggle()
    }

    let history = useHistory();

    const saveSortOrder = async () => {

        let sortOrderSavingToast = toast.loading('Updating sort order...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        try {

            await CmsApi.post('/members/sort', {
                parentToken: history.location.pathname.split('/')[history.location.pathname.split('/').length -1],
                childTokens: localDataState.map(row => row.memberToken)
            })

            await refreshMembers()

            toast.update(sortOrderSavingToast, 
                { 
                    render: "Sort order updated",
                    type: "success", 
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });

            toggle()

        } catch(e){
            toast.update(sortOrderSavingToast, 
                { 
                    render: "Error",
                    type: "error", 
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        }
        // CmsApi.post('/members/sort', {
        //     parentToken: history.location.pathname.split('/')[history.location.pathname.split('/').length -1],
        //     childTokens: data.map(row => row.memberToken)
        // })
        // .then(res => console.log(res.data))
        // .catch(err => console.log(err))
    }

    return(
        <Modal isOpen={isOpen} toggle={preToggle}>
            <ModalHeader toggle={preToggle}>
                Reorder Members
            </ModalHeader>
            <ModalBody className='sort-order-modal-body'>
            <DragDropContext 
            onDragEnd={handleOnDragEnd}
            >
                        <Droppable className="d-flex" droppableId='members'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {localDataState.map((line, i) => (
                                        <Draggable key={`${line.memberToken}`} draggableId={`${line.memberToken}`} index={i} className="d-flex">
                                            {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                            <Card className='mb-1 d-flex flex-row' style={{ height: '45px' }}>
                                                <div style={{ width: '45px', height: '100%' }} 
                                                className="bg-light d-flex justify-content-center align-items-center"
                                                {...provided.dragHandleProps}
                                                >
                                                <Icon icon={dragIcon} width="45" height="40" />
                                                </div>
                                                <div className='d-flex align-items-center ml-2' 
                                                style={{ fontSize: '17px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', width: '420px' }}>
                                                {line.memberName.length < 35 ? line.memberName : line.memberName.slice(0, 41) + '...'}
                                                </div>
                                            </Card>
                                            </div>)}</Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                {/* {data.map(line => (
                    <>
                    <Card className='mb-1 d-flex flex-row' style={{ height: '45px' }}>
                        <div style={{ width: '45px', height: '100%' }} className="bg-light d-flex justify-content-center align-items-center">
                        <Icon icon={dragIcon} width="45" height="40" />
                        </div>
                        <div className='d-flex justify-content-center align-items-center ml-2' style={{ fontSize: '17px', fontWeight: 'bold' }}>
                        {line.memberName}
                        </div>
                        </Card>
                    </>
                ))} */}
            </ModalBody>
            <ModalFooter >
                <Button color="primary" onClick={saveSortOrder}>
                    Save
                </Button>{' '}
                <Button color="secondary" onClick={preToggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ChildDragandDrop;