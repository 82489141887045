import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Card, CardBody, NavItem, Nav, NavLink, Button, CustomInput } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { MemberContext } from '../custom-contexts/MemberContext';
import { CmsApi } from '../customApi/CmsApi';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import squareEditOutline from '@iconify/icons-mdi/square-edit-outline';
import pencilIcon from '@iconify/icons-mdi/pencil';
import overlayFactory from 'react-bootstrap-table2-overlay';
import ChildDragandDrop from '../custom-components/ChildDragAndDrop';

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, 
    totalSize, showTotal = true, paginationTotalRenderer, 
    activeMember, tab, parent2, memberChildrenActive, setMemberChildrenActive,
    setShowShownOnHomepage, showShownOnHomepage    
}) => {
    
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    let history = useHistory();
    let location = useLocation();

    const rowEvents = {
        onClick: (e, row) => {
            history.push(location.pathname + '/' + row.memberToken)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };

    const paginationOptions = {
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: '100',
                value: 100,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    let parentToken = history.location.pathname.split('/')[history.location.pathname.split('/').length -1]

    return (
      <ToolkitProvider
        bootstrap4
        keyField="memberToken"
        data={data}
        columns={columns}
        search
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
        {props => (
            <React.Fragment>
                <Row className="mb-2">
                    {(tab === 'items' && !parent2.lockedChild) && <Col xs={'auto'}>
                        <Link to={location.pathname + '/newItem'} className="btn btn-danger">
                            <i className="mdi mdi-plus-circle mr-2"></i>Add Item
                        </Link>
                    </Col>}
                    {(tab === 'categories' && !parent2.lockedParent) && <Col xs={'auto'}>
                        <Link to={location.pathname + '/newCategory'} className="btn btn-danger">
                            <i className="mdi mdi-plus-circle mr-2"></i>Add Category
                        </Link>
                    </Col>}
                    {(tab === 'items' && parentToken === 'mmbr_parent2_BWHi5xue0mN4bDLYs3Urqa71') && <Col xs={'auto'} className="d-flex align-items-center">
                        <CustomInput
                            type="switch"
                            id={`show-on-homepage`}
                            checked={showShownOnHomepage}
                            onChange={(e) => setShowShownOnHomepage(e.target.checked)}
                            label="Filter by 'Show On Homepage'"
                        />
                    </Col>}
                    {((tab === 'items' && !parent2.lockedChild && parentToken === 'mmbr_parent2_D5u2obu78gOgl3PzHDzQWVU9') || 
                    (tab === 'items' && showShownOnHomepage && parentToken === 'mmbr_parent2_BWHi5xue0mN4bDLYs3Urqa71')) && <Col xs={'auto'}>
                            <ChildDragandDrop data={memberChildrenActive} setData={setMemberChildrenActive} />
                    </Col>}
                    <Col className="text-right">
                        {/* <ExportCSVButton {...props.csvProps} className="export-csv-btn btn btn-primary mr-3">
                            Export CSV
                        </ExportCSVButton> */}
                        <SearchBar className='search-bar' {...props.searchProps} placeholder='Search by name...' />
                    </Col>
                </Row>

                <BootstrapTable
                    {...props.baseProps}
                    remote
                    bordered={false}
                    headerClasses="thead-light"
                    pagination={ paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal }) }
                    wrapperClasses="table-responsive"
                    classes="product-table"
                    rowEvents={ rowEvents }
                    // selectRow={ selectRow }
                    rowClasses={ rowClasses }
                    hover
                    onTableChange={ onTableChange }
                    // DO NOT CHANGE LOADING OR OVERLAY. This ensures that the table doesn't change visually until all changes are loaded and it prevents a state where the table reloads with only partial data.
                    loading={false}  //only loading is true, react-bootstrap-table will render overlay
                    overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
                    noDataIndication={() => (
                        <div><strong>No Results</strong></div>
                    )}
                />
            </React.Fragment>
        )}
    </ToolkitProvider>
  );
  }



const TableWithPag = ({ activeMember, memberChildren, members, loading, setLoading }) => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    let parentToken = history.location.pathname.split('/')[history.location.pathname.split('/').length -1]

    function sortDataBySortNum(array){
        let sortedBySortNumData = array.sort((a, b) => parseInt(a.parentsAndSortNums.find(x => x.parentToken === parentToken)?.sortOrderNum) < parseInt(b.parentsAndSortNums.find(x => x.parentToken === parentToken)?.sortOrderNum) ? -1 : 1);
        return sortedBySortNumData
    }

    // this is a custom add on for AxlePr
    const [showShownOnHomepage, setShowShownOnHomepage] = useState(false);

    useEffect(() => {
        history.listen(() => {
            if(history.action === 'POP')
                return setLoading(false)
            setLoading(true)
        })
    }, [])

    const [tab, setTab] = useState(
        memberChildren.filter(memberChild => memberChild.memberType === 'parent3').length > 0 ?
        'categories' : 'items'
    );

    const [parent2, setParent2] = useState('');

    useEffect(() => {
        if (activeMember.memberType === 'parent2')
            setParent2(activeMember)
        else if(activeMember.memberType === 'parent3'){
            setParent2(members.find(member => member.memberToken === activeMember.parent2))   
        }
    }, [activeMember])

    const [memberChildrenActive, setMemberChildrenActive] = useState('');

    // this is a custom add on for AxlePr
    const customFiltering = (array) => {
        if(showShownOnHomepage){
            return array.filter(item => item.items['show on homepage'][0])
        } else {
            return array;
        }
        
    }

    useEffect(() => {
        if(tab && memberChildren){
            if(tab === 'categories') {
                setMemberChildrenActive(memberChildren.filter(memberChild => memberChild.memberType === 'parent3'))
            } else {
                // this is a custom add on for HavaJava
                if(parentToken === 'mmbr_parent2_D5u2obu78gOgl3PzHDzQWVU9'){
                    setMemberChildrenActive(sortDataBySortNum(memberChildren.filter(memberChild => memberChild.memberType === 'child')))
                } 
                // this is a custom add on for AxlePr
                else if (parentToken === 'mmbr_parent2_BWHi5xue0mN4bDLYs3Urqa71' && showShownOnHomepage) {
                    setMemberChildrenActive(sortDataBySortNum(customFiltering(memberChildren.filter(memberChild => memberChild.memberType === 'child'))))
                } else {
                    setMemberChildrenActive(memberChildren.filter(memberChild => memberChild.memberType === 'child'))
                }
            }
        }
    }, [tab, memberChildren, showShownOnHomepage])

    const [paginationValues, setPaginationValues] = useState({
        page: parseInt(query.get('page')),
        data: [],
        sizePerPage: parseInt(query.get('sizePerPage')),
        totalSize: ''
    })

    const [previousRoute, setPreviousRoute] = useState(location.pathname);

    const [navigated, setNavigated] = useState(false);

    useEffect(() => {
        if(previousRoute === location.pathname){
            if (!location.search){
                memberChildren.filter(memberChild => memberChild.memberType === 'parent3').length > 0 ?
            query.set('tab', 'categories') : query.set('tab', 'items');
                query.set('page', 1);
                query.set('sizePerPage', 15);
                history.replace(location.pathname + "?" + query.toString());
            }
        } else {
            setPreviousRoute(location.pathname)
        }

    }, [location.search])

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    useEffect(() => {
        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const currentIndex = (page - 1) * sizePerPage;

        let data = [];

        setPaginationValues({
            ...paginationValues,
            data: memberChildrenActive ? data.slice(currentIndex, currentIndex + sizePerPage) : ''
        })
    }, [memberChildren, memberChildrenActive])

    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const [columns, setColumns] = useState(null)

    // const returnCorrectFieldFromParent = (fieldName) => parent2.blueprints.child.find(field => field.name === fieldName);

    useEffect(() => {
        let tempColumns = [];
        
        if(memberChildrenActive && parent2){
            parent2.blueprints[tab === 'categories' ? 'parent' : 'child'].forEach(field => {
                if(field.hasOwnProperty('cmsShowOutside') && field.cmsShowOutside){
                    if(field.type === 'date'){
                        tempColumns = [
                            ...tempColumns,
                            {   
                                // headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
                                // <div className='d-flex justify-content-center'>
                                //     { column.text }
                                //     { sortElement }
                                // </div>
                                // ),
                                dataField: 'date',
                                text: field.name,
                                sort: true,
                                sortOrder: 3,
                                sortValue: (cell, row) => row.items[field.name][0],
                                formatter: (cell, row) => {
                                    let date = new Date(row.items?.hasOwnProperty(field.name) ? row.items[field.name][0] : '');
                                    let options = { year: 'numeric', month: 'long', day: 'numeric' };
                                    return (row.items?.hasOwnProperty(field.name) && row.items[field.name][0]) ? date.toLocaleDateString('en-US', options) : '';
                                },
                                style: { verticalAlign: 'middle' }
                            }
                        ]
                    } else if (field.type === 'image') {
                        tempColumns = [
                            ...tempColumns,
                            {   
                                dataField: '',
                                text: field.name,
                                sort: false,
                                sortOrder: 1,
                                formatter: (cell, row) => {
                                    if (row.items?.hasOwnProperty(field.name) && row.items[field.name][0])
                                    return (
                                    <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded">    
                                        <img src={row.items[field.name][0] + '/50'} />
                                    </div>
                                    )
                                    else
                                    return(
                                        <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded">    
                                            <img src='http://dummyimage.com/50' />
                                        </div> 
                                    )
                                }
                            }
                        ]
                    }
                }
            })

            const editCategory = (e, memberToken) => {
                e.stopPropagation()
                history.push(location.pathname + '/' + memberToken + '/editCategory')
            }
    
            tempColumns = [
                ...tempColumns,
                {   
                    // headerFormatter: (column, colIndex, { sortElement, filterElement }) => (
                    //     <div className='d-flex justify-content-center'>
                    //         { column.text }
                    //         { sortElement }
                    //     </div>
                    //     ),
                    dataField: 'memberName',
                    text: 'Name',
                    sort: true,
                    sortOrder: 2,
                    style: { verticalAlign: 'middle' },
                    formatter: (cell, row) => (
                        <div className='d-flex'>
                            <span className='align-self-center'>{row.memberName}</span>
                            {row.memberType === 'parent3' && <Button color='primary' 
                            className='align-self-start ml-1 mb-2 btn-icon btn-circle btn-circle-sm d-flex align-items-center justify-content-center'
                            onClick={(e) => editCategory(e, row.memberToken)}
                            >
                                <Icon icon={pencilIcon} width="14" height="14"/>
                            </Button>}
                        </div>
                    )
                }
            ]
    
            tempColumns.sort((a, b) => {
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                } else if (b.sortOrder > a.sortOrder) {
                    return -1;
                }
                return 0;
            })
            
    
            setColumns(tempColumns);
        }

    }, [activeMember, memberChildrenActive, parent2])

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const [tableChangeType, setTableChangeType] = useState('');

    // When pagination query params change, update the pagination values
    useEffect(() => {
        if ((!loading && !location.search)){
            memberChildren.filter(memberChild => memberChild.memberType === 'parent3').length > 0 ?
        query.set('tab', 'categories') : query.set('tab', 'items');
            query.set('page', 1);
            query.set('sizePerPage', 15);
            history.replace(location.pathname + "?" + query.toString());
        }
        const tabValue = query.get('tab')
        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const currentIndex = (page - 1) * sizePerPage;
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchText = query.get('searchText')

        setTab(tabValue);

        let result = [];

        if(memberChildrenActive && parent2) {
            result = memberChildrenActive;

        if (searchText) {
            //  If user search something
            //  You can ignore this part, it's just some search logic
            result = result.filter((row) => {
              for (let cidx = 0; cidx < columns.length; cidx += 1) {
                const column = columns[cidx];
                let targetValue = row[column.dataField];
                if (targetValue !== null && typeof targetValue !== 'undefined') {
                  targetValue = targetValue.toString().toLowerCase();
                  if (targetValue.indexOf(searchText.toLowerCase()) > -1) {
                    return true;
                  }
                }
              }
              return false;
            });
          }
        
        if (sortOrder === 'asc') {
            if (sortField === 'date'){

                let type = tab === 'categories' ? 'parent' : 'child';
                
                let fieldName = parent2.blueprints[type].find(item => item.type === 'date').name;

                result.forEach(res => {
                    if(!res.hasOwnProperty('items'))
                        res.items = {};
                })

                result.forEach(res => {
                    if(!res.items.hasOwnProperty(fieldName))
                        res.items[fieldName] = [''];
                })

                result = result.sort((a, b) => {
                    if (a['items'][fieldName][0] > b['items'][fieldName][0]) {
                        return 1;
                    } else if (b['items'][fieldName][0] > a['items'][fieldName][0]) {
                        return -1;
                    }
                    return 0;
                    });
            } else{

                result = result.sort((a, b) => {
                    if (a[sortField] > b[sortField]) {
                        return 1;
                    } else if (b[sortField] > a[sortField]) {
                        return -1;
                    }
                    return 0;
                    }); 
            }
        } else {
            if (sortField === 'date'){
                let fieldName = parent2.blueprints.child.find(item => item.type === 'date').name;

                result.forEach(res => {
                    if(!res.hasOwnProperty('items'))
                        res.items = {};
                })

                result.forEach(res => {
                    if(!res.items.hasOwnProperty(fieldName))
                        res.items[fieldName] = [''];
                })

                result = result.sort((a, b) => {
                    if (a['items'][fieldName][0] > b['items'][fieldName][0]) {
                        return -1;
                    } else if (b['items'][fieldName][0] > a['items'][fieldName][0]) {
                        return 1;
                    }
                    return 0;
                    });
            } else {
                result = result.sort((a, b) => {
                    if (a[sortField] > b[sortField]) {
                        return -1;
                    } else if (b[sortField] > a[sortField]) {
                        return 1;
                    }
                    return 0;
                    });
            }
        }}

        setPaginationValues({
            page: page || 0,
            sizePerPage: sizePerPage || 0,
            data: result.slice(currentIndex, currentIndex + sizePerPage),
            totalSize: result.length || 0
        })

        setLoading(false)

        return 

    }, [location.search, memberChildren, memberChildrenActive, location.pathname])

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage, searchText, filters }) => {
        setTableChangeType(type);
        if (type === 'pagination'){
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort'){
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        if (type === 'search'){
            query.set('searchText', searchText);
        }
        history.push(location.pathname + "?" + query.toString());

      }

    const { data, sizePerPage, page, totalSize } = paginationValues;

    if(columns && memberChildrenActive && parent2){
        return(
            <>
            <Nav tabs 
            className="nav-justified"
            >
            <NavItem>
                <NavLink
                    href="#"
                    active={tab === 'categories'}
                    // className={classnames({ active: activeTab === tab.id })}
                    onClick={() => {
                        if(tab === 'categories')
                            return
                        query.delete('sortOrder')
                        query.delete('sortField');
                        query.set('tab', 'categories');
                        history.push(location.pathname + "?" + query.toString());
                    }}
                    >
                    <i
                        className={classnames(
                            'd-lg-none',
                            'd-block',
                            'mr-1'
                        )}></i>
                    <span className="d-none d-lg-block">Categories</span>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    href="#"
                    // className="active"
                    active={tab === 'items'}
                    // className={classnames({ active: activeTab === tab.id })}
                    // onClick={() => {
                    //     this.toggle(tab.id);
                    // }}
                    onClick={() => {
                        if(tab === 'items')
                            return
                        query.delete('sortOrder');
                        query.delete('sortField');
                        query.set('tab', 'items');
                        history.push(location.pathname + "?" + query.toString());
                    }}
                    >
                    <i
                        className={classnames(
                            'd-lg-none',
                            'd-block',
                            'mr-1'
                        )}></i>
                    <span className="d-none d-lg-block">Items</span>
                </NavLink>
            </NavItem>
            </Nav>
            <Card className='border-bottom border-right border-left rounded-bottom rounded-top-0' style={{ borderRadius: '0px' }}>
                <CardBody>
                <RemotePagination
                    data={ data }
                    page={ page }
                    columns={columns}
                    sizePerPage={ sizePerPage }
                    totalSize={ totalSize }
                    onTableChange={ onTableChange }
                    paginationTotalRenderer={customTotal}
                    showTotal={true}
                    loading={loading}
                    activeMember={activeMember}
                    memberChildrenActive={sortDataBySortNum(memberChildrenActive)}
                    setMemberChildrenActive={setMemberChildrenActive}
                    tab={tab}
                    parent2={parent2}
                    showShownOnHomepage={showShownOnHomepage}
                    setShowShownOnHomepage={setShowShownOnHomepage}
                />
                    </CardBody>
                    </Card>
                </>
        )
    } else {
        return(
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
        )
    }
}

export default TableWithPag;