import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { CmsApi } from '../customApi/CmsApi';
import { UserContext } from './UserContext';

export const MemberContext = createContext();

export const MemberProvider = props => {

    const {user} = useContext(UserContext)
    
    const [members, setMembers] = useState('');

    const [topCategory, setTopCategory] = useState([]);

    const [sidebarCategories, setSidebarCategories] = useState([])

    const refreshMembers = async () => {
        let completed = false;
        let { data } = await CmsApi.get('/members');

        let memberArray = Object.values(data)

        setMembers(memberArray) 

        // console.log(newMembers)
            // .then(res => {
            //     let memberArray = Object.values(res.data)
            //     setMembers(memberArray) 
            // })
            // .catch(err => console.log(err))
    }

    useEffect(() => {
        if(user){
            CmsApi.get('/members')
            .then(res => {
                let memberArray = Object.values(res.data)

                let topCat = memberArray.filter(member => member.top)

                setTopCategory(topCat[0])
                setSidebarCategories(memberArray.filter(member => member.parentTokens.includes(topCat[0].memberToken) && !member.top))
                setMembers(memberArray) 
            })
        }
    }, [user])

    return (
        <MemberContext.Provider value={{ members, setMembers, topCategory, sidebarCategories, refreshMembers}}>
            {props.children}
        </MemberContext.Provider>
    );

}